input:hover,
input:active,
input:focus {
    outline: 0px !important;
    -webkit-appearance: none;
    box-shadow: none !important;
}

.table-row {
    cursor: pointer;
}
.table-row:hover {
    background-color: rgb(238, 238, 238);
}
